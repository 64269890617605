@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

html {
  scroll-behavior: smooth;
}

.living {
  display: inline-block;
  width: 14px;
  height: 14px;
  position: relative;
  margin-left: 14px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 250%;
    height: 250%;
    box-sizing: border-box;
    margin-left: -75%;
    margin-top: -75%;
    border-radius: 45px;
    background-color: #4caf50;
    animation: pulse 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #4caf50;
    border-radius: 50px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

.chartLiving {
  &:after {
    content: "";
    position: absolute;
    left: -10%;
    top: -10%;
    display: block;
    width: 120%;
    height: 120%;
    background-color: #4caf5035;
    border-radius: 50px;
    animation: circle 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.price_green {
  animation: price_green 6s;
}
.price_red {
  animation: price_red 6s;
}

@keyframes price_green {
  0% {
    color: #62d2cf;
  }
  50% {
    color: #62d2cf;
  }
  100% {
    color: white;
  }
}

@keyframes price_red {
  0% {
    color: #ed524f;
  }
  50% {
    color: #ed524f;
  }
  100% {
    color: white;
  }
}

.v1 {
  font-family: "Open Sans", sans-serif;

  .MuiTableCell-root,
  .MuiTypography-h1,
  .MuiTypography-h2,
  .MuiTypography-h3,
  .MuiTypography-h4,
  .MuiTypography-h5,
  .MuiTypography-h6,
  .MuiTypography-body1,
  .MuiTypography-body2,
  .MuiChip-label,
  .MuiButton-root,
  .MuiTab-root,
  .MuiTooltip-popper,
  button,
  .MuiInputBase-input,
  .MuiFormLabel-root {
    font-family: "Open Sans", sans-serif;
    color: white;
  }

  .MuiTypography-h5 {
    font-size: 20px;
    font-weight: 600;
  }

  .MuiDivider-root {
    background: #3e4553;
  }

  .MuiPaper-root {
    background: #212a3a;
    border-radius: 4px;
    color: white;
    box-shadow: none;

    .MuiPaper-root {
      background: #111e31;
    }
  }

  .MuiChip-root {
    background: #62d2cf;

    .MuiChip-label {
      color: #103469;
    }
  }

  .MuiChip-clickable:hover,
  .MuiChip-clickable:focus {
    background: white;
  }

  .MuiTypography-colorPrimary {
    color: #62d2cf;
    transition: all 0.3s ease;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  .MuiStepIcon-root {
    color: #62d2cf;

    &.MuiStepIcon-active {
      color: white;
    }
    &.MuiStepIcon-completed {
      color: white;
    }
  }
  .MuiStepIcon-text {
    fill: #103469;
  }

  .MuiStepLabel-label.MuiStepLabel-active,
  .MuiStepLabel-label.MuiStepLabel-completed {
    color: white;
  }

  .MuiButton-contained,
  .MuiButton-containedPrimary {
    background: #62d2cf;
    color: #103469;

    &:hover {
      background: white;
    }
  }

  .MuiButton-root.Mui-disabled,
  .MuiButton-contained.Mui-disabled {
    color: rgba(255, 255, 255, 0.26);
  }

  .MuiTab-textColorPrimary.Mui-selected {
    background: #62d2cf;
    color: #103469;
  }
  .MuiTabs-indicator {
    background: #62d2cf;
  }

  .MuiTableCell-stickyHeader {
    background: #111e31;
  }

  .MuiTableCell-root {
    border-bottom-color: #3e4553;
  }

  .color-green {
    color: #62d2cf;
  }
  .color-red {
    color: #ed524f;
  }

  .color-bsc {
    color: #f3ba2f;
  }
  .color-eth {
    color: #8b8b8b;
  }
  .color-polygon {
    color: #8b42f0;
  }

  .MuiFormLabel-root {
    color: #ffffff70 !important;
  }

  .MuiNativeSelect-icon {
    color: white;
  }
}
